import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../../core/interceptors/axios-instance';
import { DEPLOYMENT_ENV, SERVER_API_URL } from '../constants/constants';

const getServerApiUrl = () => {
  const DEMO_USER_SERVER_API_URL =
    'https://gixbyoppwk.execute-api.us-east-1.amazonaws.com/';
  const userData = JSON.parse(localStorage.getItem('userData'));
  return DEPLOYMENT_ENV === 'DEV' && userData?.email === 'demo@breezsecurity.io'
    ? DEMO_USER_SERVER_API_URL
    : SERVER_API_URL;
};

export interface TAxiosCustomConfig {
  variables?: string;
  setLoading?: (res: true | false) => void;
}
export interface Config extends AxiosRequestConfig {
  custom?: TAxiosCustomConfig;
}

export async function apiGetTransactions<T = any>(
  resource: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.get<T>(`${resource}`, config);
}

export async function apiGet<T = any>(
  resource: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.get<T>(`${getServerApiUrl()}${resource}`, config);
}

export async function apiPost<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.post<T>(`${getServerApiUrl()}${resource}`, data, config);
}

export async function apiPut<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.put<T>(`${getServerApiUrl()}${resource}`, data, config);
}

export async function apiPatch<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.patch<T>(
    `${getServerApiUrl()}${resource}`,
    data,
    config
  );
}

export async function apiDelete<T = any>(
  resource: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.delete<T>(`${getServerApiUrl()}${resource}`, config);
}
