import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAnalyticsDataAPI } from '../apis/DashboardApi';

interface DashboardState {
  appsUsage: any;
  loadingAppsUsage: boolean;
  identityTrend: any;
  loadingIdentityTrend: boolean;
  shadowApps: any;
  loadingShadowApps: boolean;
  saasRead: any;
  loadSaasRead: boolean;
  saasDownload: any;
  loadSaasDownload: boolean;
  saasEmails: any;
  loadSaasEmails: boolean;
  roleDetails: any;
  loadRoleDetails: boolean;
  rolesUsage: any;
  loadRolesUsage: boolean;
  activeIdentities: any;
  loadActiveIdentities: boolean;
  geoLocation: any;
  loadGeoLocation: boolean;
}
const initialState: DashboardState = {
  appsUsage: {} as any,
  loadingAppsUsage: false,
  identityTrend: {} as any,
  loadingIdentityTrend: false,
  shadowApps: {} as any,
  loadingShadowApps: false,
  saasRead: {} as any,
  loadSaasRead: false,
  saasDownload: {} as any,
  loadSaasDownload: false,
  saasEmails: {} as any,
  loadSaasEmails: false,
  roleDetails: {} as any,
  loadRoleDetails: false,
  rolesUsage: {} as any,
  loadRolesUsage: false,
  activeIdentities: {} as any,
  loadActiveIdentities: false,
  geoLocation: {} as any,
  loadGeoLocation: false
};

export const getAppsUsageAnalytics = createAsyncThunk(
  'analytics/getAppsUsageAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getIdentityTrendAnalytics = createAsyncThunk(
  'analytics/getIdentityTrendAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getShadowAppsAnalytics = createAsyncThunk(
  'analytics/getShadowAppsAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getSaasReadAnalytics = createAsyncThunk(
  'analytics/getSaasReadAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getSaasDownloadAnalytics = createAsyncThunk(
  'analytics/getSaasDownloadAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getSaasEmailAnalytics = createAsyncThunk(
  'analytics/getSaasEmailAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getRoleDetailsAnalytics = createAsyncThunk(
  'analytics/getRoleDetailsAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getRoleUsageAnalytics = createAsyncThunk(
  'analytics/getRoleUsageAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getActiveIdentitiesAnalytics = createAsyncThunk(
  'analytics/getActiveIdentitiesAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const getGeoLocationAnalytics = createAsyncThunk(
  'analytics/getGeoLocationAnalytics',
  async (data: any) => {
    const { payload, type } = data;
    const response = await getAnalyticsDataAPI(payload, type);
    return response;
  }
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAppsUsageAnalytics.pending, (state) => {
        state.loadingAppsUsage = true;
      })
      .addCase(
        getAppsUsageAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAppsUsage = false;
          if (action.payload) {
            state.appsUsage = action.payload;
          }
        }
      )
      .addCase(getAppsUsageAnalytics.rejected, (state) => {
        state.loadingAppsUsage = false;
      })
      .addCase(getIdentityTrendAnalytics.pending, (state) => {
        state.loadingIdentityTrend = true;
      })
      .addCase(
        getIdentityTrendAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingIdentityTrend = false;
          if (action.payload) {
            state.identityTrend = action.payload;
          }
        }
      )
      .addCase(getIdentityTrendAnalytics.rejected, (state) => {
        state.loadingIdentityTrend = false;
      })
      .addCase(getShadowAppsAnalytics.pending, (state) => {
        state.loadingShadowApps = true;
      })
      .addCase(
        getShadowAppsAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingShadowApps = false;
          if (action.payload) {
            state.shadowApps = action.payload;
          }
        }
      )
      .addCase(getShadowAppsAnalytics.rejected, (state) => {
        state.loadingShadowApps = false;
      })
      .addCase(getSaasReadAnalytics.pending, (state) => {
        state.loadSaasRead = true;
      })
      .addCase(
        getSaasReadAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadSaasRead = false;
          if (action.payload) {
            state.saasRead = action.payload;
          }
        }
      )
      .addCase(getSaasReadAnalytics.rejected, (state) => {
        state.loadSaasRead = false;
      })
      .addCase(getSaasDownloadAnalytics.pending, (state) => {
        state.loadSaasDownload = true;
      })
      .addCase(
        getSaasDownloadAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadSaasDownload = false;
          if (action.payload) {
            state.saasDownload = action.payload;
          }
        }
      )
      .addCase(getSaasDownloadAnalytics.rejected, (state) => {
        state.loadSaasDownload = false;
      })
      .addCase(getSaasEmailAnalytics.pending, (state) => {
        state.loadSaasEmails = true;
      })
      .addCase(
        getSaasEmailAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadSaasEmails = false;
          if (action.payload) {
            state.saasEmails = action.payload;
          }
        }
      )
      .addCase(getSaasEmailAnalytics.rejected, (state) => {
        state.loadSaasEmails = false;
      })
      .addCase(getRoleDetailsAnalytics.pending, (state) => {
        state.loadRoleDetails = true;
      })
      .addCase(
        getRoleDetailsAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadRoleDetails = false;
          if (action.payload) {
            state.roleDetails = action.payload;
          }
        }
      )
      .addCase(getRoleDetailsAnalytics.rejected, (state) => {
        state.loadRoleDetails = false;
      })
      .addCase(getRoleUsageAnalytics.pending, (state) => {
        state.loadRolesUsage = true;
      })
      .addCase(
        getRoleUsageAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadRolesUsage = false;
          if (action.payload) {
            state.rolesUsage = action.payload;
          }
        }
      )
      .addCase(getRoleUsageAnalytics.rejected, (state) => {
        state.loadRolesUsage = false;
      })
      .addCase(getActiveIdentitiesAnalytics.pending, (state) => {
        state.loadActiveIdentities = true;
      })
      .addCase(
        getActiveIdentitiesAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadActiveIdentities = false;
          if (action.payload) {
            state.activeIdentities = action.payload;
          }
        }
      )
      .addCase(getActiveIdentitiesAnalytics.rejected, (state) => {
        state.loadActiveIdentities = false;
      })
      .addCase(getGeoLocationAnalytics.pending, (state) => {
        state.loadGeoLocation = true;
      })
      .addCase(
        getGeoLocationAnalytics.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadGeoLocation = false;
          if (action.payload) {
            state.geoLocation = action.payload;
          }
        }
      )
      .addCase(getGeoLocationAnalytics.rejected, (state) => {
        state.loadGeoLocation = false;
      });
  }
});
export default analyticsSlice.reducer;
