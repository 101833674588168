import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

export const BarChart = ({ seriesData, handleDataPointSelection }) => {
  const barChartptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar: {
        show: false
      },
      offsetY: -20,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleDataPointSelection(event, chartContext, config);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80',
        distributed: true,
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    dataLabels: {
      style: {
        fontWeight: 600
      },
      enabled: true,
      textAnchor: 'start',
      formatter: function (val, opt) {
        if (val) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
        }
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    grid: {
      padding: {
        left: 0,
        top: 0
      },
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },

      labels: {
        show: false
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    series: []
  };
  const [chartOptions, setChartOptions] = useState(barChartptions);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: [
        {
          data: seriesData
        }
      ]
    });
  }, [seriesData]);

  return (
    <Box overflow={'scroll'} height={300} className="hide-scrollbar">
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="bar"
        height={seriesData?.length * 62}
      />
    </Box>
  );
};
