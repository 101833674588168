import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { DEPLOYMENT_ENV } from 'src/shared/constants/constants';

const SENTRY_DNS = {
    DEV: "https://4342315b138a1591bc9b6948375b27ce@o4507929373573120.ingest.us.sentry.io/4507929383993344",
    PROD: "https://9f41a040ac4359496c80fdd15a7e0bfd@o4507929373573120.ingest.us.sentry.io/4507929388843008",
}

const DEPLOYED = !(window.location.hostname === 'localhost')

export const configureSentry = (): void => {
  if (DEPLOYED) {
    Sentry.init({
      dsn: SENTRY_DNS[DEPLOYMENT_ENV],
      environment: DEPLOYMENT_ENV,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.1,
      profilesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      release: null,
    });
  }
};

export const setSentryUser = (email: string): void => {
  if (DEPLOYED) {
    const uuid = uuidv4();
    Sentry.setUser({ id: uuid, email });
  }
};

export const clearSentryUser = (): void => {
  if (DEPLOYED) {
    Sentry.setUser(null);
  }
};