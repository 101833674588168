import { apiGet, apiPost } from 'src/shared/utils/api-request';

export const getIntegrationsApi = async () => {
  const res = await apiGet('integrations');
  return res;
};

export const updateIntegrationsApi = async (data: any) => {
  const res = await apiPost('integrations/update', data);
  return res;
};

export const deleteIntegrationsApi = async (data: any) => {
  const res = await apiPost('integrations/delete', data);
  return res;
};

export const integrationsDetailApi = async (data: any) => {
  const res = await apiPost('integrations/details', data);
  return res;
};

export const webhookDetailApi = async (data: any) => {
  const res = await apiPost('webhook/get', data);
  return res;
};

export const updateWebhookDetailApi = async (data: any) => {
  const res = await apiPost('webhook/update', data);
  return res;
};

export const deleteWebhookDetailApi = async (data: any) => {
  const res = await apiPost('webhook/delete', data);
  return res;
};
