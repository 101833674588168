import { showErrorMessage } from '../components/toaster';
import { setLoadingAPIRequest } from '../../shared/components/LoadingComponent/services/loadingComponent.service';
import { store } from 'src/store/configure-store';
import { GridValidRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  DATE_TIME_FORMAT,
  INPUT_DATE_TIME_FORMAT
} from '../constants/constants';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

//all apis calls exception handling
export const handleApiRequest = async (request) => {
  let res;
  try {
    res = await request;
  } catch (error) {
    store.dispatch(setLoadingAPIRequest(false));
    showErrorMessage(error.response.data.details);
  }
  return res;
};

export const getAlertChipColor = (priority: string) => {
  switch (priority?.toUpperCase()) {
    case 'CRITICAL':
    case 'CLOSED':
    case 'AWS':
    case 'MACHINE':
    case 'INACTIVE':
    case 'EXPIRED':
    case 'CLOSED':
    case 'FAILURE':
      return 'error';
    case 'HIGH':
    case 'OKTA':
    case 'CODE':
    case 'IN_PROGRESS':
    case 'DISABLED':
    case 'SUSPENDED':
      return 'warning';
    case 'MEDIUM':
    case 'HUMAN':
    case 'REMEDIATED':
    case 'NOT_SETUP':
    case 'REMEDIATED':
    case 'STAGED':
      return 'primary';
    case 'LOW':
    case 'OPEN':
    case 'ACTIVE':
    case 'SUCCESS':
      return 'success';
    case 'ASSIGNED_TO':
    case 'PROVISIONED':
      return 'info';
    default:
      return 'error';
  }
};

export const getAlertColor = (priority: string) => {
  switch (priority?.toUpperCase()) {
    case 'CRITICAL':
      return 'alert-critical';
    case 'HIGH':
      return 'alert-high';
    case 'MEDIUM':
      return 'alert-medium';
    case 'LOW':
      return 'alert-low';
    default:
      return 'alert-critical';
  }
};

export const getOperationChipColor = (operation: string) => {
  switch (operation) {
    case 'Create':
      return 'primary';
    case 'Update':
      return 'warning';
    case 'Delete':
      return 'error';
    default:
      return 'primary';
  }
};

export const getNameFromArn = (Arn: string) => {
  const ArnSubStrArr = Arn?.split('/');
  return ArnSubStrArr?.length > 0 ? ArnSubStrArr[ArnSubStrArr.length - 1] : Arn;
};

export const replaceSubStringFromArn = (Arn: string) => {
  const splitArnBySlash = Arn?.split('/');
  const arnSubString = splitArnBySlash[0];
  const splitArnSubStringByColan = arnSubString.split(':');
  const stringToReplace =
    splitArnSubStringByColan[splitArnSubStringByColan.length - 1];
  return arnSubString.replace(stringToReplace, '');
};

export const dataTableExtraProps: GridValidRowModel = {
  sortable: false,
  align: 'center',
  headerAlign: 'center',
  flex: 1
};

export const getResourceType = (eventSource: string) => {
  if (eventSource) {
    const eventSourceStrArr = eventSource?.split('.');
    return eventSourceStrArr.length
      ? eventSourceStrArr[0].toUpperCase()
      : eventSource;
  }
};

export const getGeoLocation = (geoData) => {
  if (geoData) {
    geoData = JSON.parse(JSON.stringify(geoData));
    let geoDataValues = [];
    if (geoData?.city) geoDataValues.push(geoData?.city);
    if (geoData?.region) geoDataValues.push(geoData?.region);
    if (geoData?.country) geoDataValues.push(geoData?.country);
    return geoDataValues.toString();
  }
};

export const acknowledgementChipColor = (status: string) => {
  switch (status) {
    case 'DENIED':
      return 'error';
    case 'PENDING_ACKNOWLEDGEMENT':
      return 'warning';
    case 'ACKNOWLEDGED':
      return 'success';
    default:
      return 'warning';
  }
};

export const parseAndFormatDateTime = (
  inputDateTime: string,
  inputDateTimeFormat = INPUT_DATE_TIME_FORMAT
) => {
  return dayjs(inputDateTime, inputDateTimeFormat).format(DATE_TIME_FORMAT);
};

export const formatDateTime = (inputDateTime: string) => {
  return dayjs(inputDateTime).format(DATE_TIME_FORMAT);
};

export const formatEpochDateTime = (inputDateTime: string) => {
  return dayjs(parseInt(inputDateTime) / 1000000).format(DATE_TIME_FORMAT);
};

export const geRiskScoreColorAndLabel = (riskScore) => {
  let chartColor = ['#58C923', 'rgba(91, 206, 0, 0.31)'];
  let chartLabel = 'LOW';
  switch (true) {
    case riskScore < 40:
      chartColor = ['#58C923', 'rgba(91, 206, 0, 0.31)'];
      chartLabel = 'LOW';
      break;

    case riskScore > 40 && riskScore < 75:
      chartColor = ['#FBC62F', 'rgba(251, 198, 47, 0.31)'];
      chartLabel = 'MEDIUM';
      break;
    case riskScore > 75:
      chartColor = ['#F00', 'rgba(255, 0, 0, 0.31)'];
      chartLabel = 'HIGH';
      break;

    default:
      chartColor = ['#58C923', 'rgba(91, 206, 0, 0.31)'];
      chartLabel = 'LOW';
      break;
  }
  return { chartColor, chartLabel };
};

export const getDarkColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

export const getSourceChipColor = (data: string) => {
  switch (data?.toUpperCase()) {
    case 'AWS':
    case 'AWS GuardDuty':
      return 'primary';
    case 'OKTA':
      return 'success';
    case 'VPC':
      return 'warning';
    case 'AZURE':
      return 'error';
    case 'GCP':
      return 'info';
    default:
      return 'primary';
  }
};

export const getRiskScoreColorClasses = (
  riskScore: number,
  isAllowed: boolean
) => {
  let labelColor = 'priority-low-label';
  let labelBorderColor = 'priority-low-border';
  switch (isAllowed) {
    case riskScore <= 4:
      labelColor = 'priority-low-label';
      labelBorderColor = 'priority-low-border';
      break;

    case riskScore > 5 && riskScore <= 6.5:
      labelColor = 'priority-medium-label';
      labelBorderColor = 'priority-medium-border';
      break;
    case riskScore > 6.5 && riskScore <= 8:
      labelColor = 'priority-high-label';
      labelBorderColor = 'priority-high-border';
      break;
    case riskScore > 8 && riskScore <= 10:
      labelColor = 'priority-critical-label';
      labelBorderColor = 'priority-critical-border';
      break;
    default:
      labelColor = 'priority-low-label';
      labelBorderColor = 'priority-low-border';
      break;
  }
  return { labelColor, labelBorderColor };
};

type ChipColor =
  | 'error'
  | 'warning'
  | 'primary'
  | 'success'
  | 'info'
  | 'default'
  | 'secondary';

export const getRiskScoreColorAndLabel = (riskScore: number) => {
  let label = 'LOW';
  let chipColor: ChipColor = 'success';
  switch (true) {
    case riskScore <= 4:
      label = 'LOW';
      chipColor = 'success';
      break;
    case riskScore > 5 && riskScore <= 6.5:
      label = 'MEDIUM';
      chipColor = 'primary';
      break;
    case riskScore > 6.5 && riskScore <= 8:
      label = 'HIGH';
      chipColor = 'warning';
      break;
    case riskScore > 8 && riskScore <= 10:
      label = 'CRITICAL';
      chipColor = 'error';
      break;
    default:
      label = 'LOW';
      chipColor = 'success';
      break;
  }
  return { label, chipColor };
};
