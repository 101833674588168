import { combineReducers } from '@reduxjs/toolkit';
import loadingComponentReducer from '../../shared/components/LoadingComponent/services/loadingComponent.service';
import drawerReducer from '../../shared/components/drawer/services/drawer.service';
import RuntimeReducer from '../../modules/Runtime/services/human.runtime.service';
import CodeRuntimeReducer from '../../modules/Runtime/services/code.runtime.service';
import MachineIdentityReducer from '../../modules/Runtime/services/machie.runtime.service';
import UsersIdentityInventoryReducer from '../../modules/IdentityInventory/services/users.identityInventory.service';
import RolesIdentityInventoryReducer from '../../modules/IdentityInventory/services/roles.identityInventory.service';
import GroupsIdentityInventoryReducer from '../../modules/IdentityInventory/services/groups.identityInventory.service';
import DashboardReducer from '../../modules/Dashboard/services/dashboard.service';
import RolesUsageReducer from '../../modules/Dashboard/services/rolesUsage.service';
import PoliciesReducer from '../../modules/PoliciesV2/services/policies.service';
import AwsAlertsReducer from '../../modules/AwsAlertsV2/services/awsAlerts.service';
import IdentityFingerprintReducer from '../../modules/IdentityFingerprint/services/identityFingerprint.service';
import rolesReportReducer from '../../modules/IdentityFingerprint/services/rolesReport.service';
import permissionSearchReducer from '../../modules/IdentityFingerprint/services/permissionSearch.service';
import jitRoleReducer from '../../modules/PrivilegeManagement/services/jitRole.service';
import jitSSORuleReducer from '../../modules/PrivilegeManagement/services/jitSSORule.service';
import settingsReducer from '../../modules/SettingsV2/services/settings.service';
import activitiesReducer from '../../modules/ActivityLog/services/activity-log.service';
import commonReducer from '../../modules/common/services/common.services';
import nonHumanIdentityInventoryReducer from '../../modules/IdentityInventory/services/nonhuman.identityInventory.service';
import investigationV2Reducer from '../../modules/InvestigationChangeManagementV2/services/investigation.service';
import changeManagementV2Reducer from '../../modules/InvestigationChangeManagementV2/services/change-management.service';
import identityFingerPrintV2Reducer from '../../modules/IdentityFingerprintV2/services/identity-fingerprint.service';
import permissionSearchV2Reducer from '../../modules/IdentityFingerprintV2/services/permission-search.service';
import rolesReportV2Reducer from '../../modules/IdentityFingerprintV2/services/roles-report.service';
import appsIdentityInventoryReducer from '../../modules/IdentityInventory/services/apps.identityInventory.service';
import remediationsReducer from '../../modules/Remediations/services/remediations.service';
import actionsReducer from '../../modules/Actions/services/actions.service';
import analyticsReducer from '../../modules/Dashboard/services/analytics.service';
import telemetryReducer from '../../modules/IdentityTelemetry/services/telemetry.service';
import alertActionReducer from '../../modules/AwsAlertsV2/services/alertAction.service';
import integrationReducer from '../../modules/Integrations/services/integration.service';
import { RootState } from '../configure-store';

const combinedReducer = combineReducers({
  common: commonReducer,
  loadingAPIRequest: loadingComponentReducer,
  runtime: RuntimeReducer,
  codeRuntime: CodeRuntimeReducer,
  machineRuntime: MachineIdentityReducer,
  contentDrawer: drawerReducer,
  usersIdentityInventory: UsersIdentityInventoryReducer,
  rolesIdentityInventory: RolesIdentityInventoryReducer,
  groupsIdentityInventory: GroupsIdentityInventoryReducer,
  nonHumanIdentityInventory: nonHumanIdentityInventoryReducer,
  dashboard: DashboardReducer,
  analytics: analyticsReducer,
  policies: PoliciesReducer,
  awsAlerts: AwsAlertsReducer,
  rolesUsage: RolesUsageReducer,
  identityFingerprint: IdentityFingerprintReducer,
  rolesReport: rolesReportReducer,
  permissionSearch: permissionSearchReducer,
  jitRole: jitRoleReducer,
  jitSSORule: jitSSORuleReducer,
  settings: settingsReducer,
  activities: activitiesReducer,
  investigationV2: investigationV2Reducer,
  changeManagementV2: changeManagementV2Reducer,
  identityFingerprintV2: identityFingerPrintV2Reducer,
  permissionSearchV2: permissionSearchV2Reducer,
  rolesReportV2: rolesReportV2Reducer,
  appsIdentityInventory: appsIdentityInventoryReducer,
  remediations: remediationsReducer,
  actions: actionsReducer,
  telemetry: telemetryReducer,
  alertAction: alertActionReducer,
  integration: integrationReducer
});
const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logout') {
    state = undefined;
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
