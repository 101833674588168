import { FC, ReactNode, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import Sidebar from '../side-bar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store/configure-store';
import {
  getPlaneCollection,
  getPolicyMasterData,
  getUserAccounts
} from 'src/modules/common/services/common.services';
import { getIntegrationsDetails } from 'src/modules/Integrations/services/integration.service';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { policyMasterData, planeCollection, userAccounts } = useSelector(
    (state: RootState) => state.common
  );
  const { integrationDetails } = useSelector(
    (state: RootState) => state.settings
  );

  //preloading data for performance improvement
  useEffect(() => {
    if (!(policyMasterData && Object.entries(policyMasterData)?.length))
      dispatch(getPolicyMasterData());
    if (!userAccounts?.length) dispatch(getUserAccounts());
    const data = {
      limit: 1000
    };
    if (!planeCollection?.length) dispatch(getPlaneCollection());
    if (!integrationDetails?.length) dispatch(getIntegrationsDetails(data));
  }, []);

  return (
    <Box
      className={
        theme.palette.mode === 'dark'
          ? 'layoutbox MuiPageTitlewrapperDark '
          : 'layoutbox MuiPageTitlewrapperLight '
      }
    >
      <Header />
      <Sidebar />
      <Box
        className="layout"
        sx={{
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`
          }
        }}
      >
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
