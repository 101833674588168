import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import router from './router/router';
import './scss/commonStyle.scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { themeCreator } from '../src/core/theme/base';
import { store } from '../src/store/configure-store';
import { AuthProvider } from './providers/AuthguardContext';
import { LoadingComponent } from './shared/components/index';
import { configureSentry } from './shared/utils/sentry';

configureSentry()

const App = () => {
  const content = useRoutes(router);
  const curThemeName = 'NebulaFighterTheme';
  const theme = themeCreator(curThemeName);

  return (
    <Provider store={store}>
      <AuthProvider>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              {content}
              <LoadingComponent />
            </LocalizationProvider>
          </ThemeProvider>
        </StylesProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
