import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost
} from 'src/shared/utils/api-request';

export const getBreezUsers = async () => {
  const res = await apiGet('user');
  return res;
};

export const createBreezUserApi = async (data: any) => {
  const res = await apiPost(`user`, data);
  return res;
};

export const updateBreezUserApi = async (email: string, data: any) => {
  const res = await apiPatch(`user/profile/${email}`, data);
  return res;
};

export const deleteBreezUserApi = async (email: string) => {
  const res = await apiDelete(`user/profile/${email}`);
  return res;
};

export const changePasswordAPI = async (data: any) => {
  const res = await apiPatch('user/change-password', data);
  return res;
};

export const getUserProfileAPI = async () => {
  const res = await apiGet('user/profile');
  return res;
};

export const updateUserProfileAPI = async (data: any) => {
  const res = await apiPatch('user/profile', data);
  return res;
};
