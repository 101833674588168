import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthguardContext';

interface IGuardedRouteProps {
  component: any;
  path?: string;
}

export const BaseGuardRoutes = ({
  component: RouteComponent
}: IGuardedRouteProps) => {
  const { userLogInfo } = useAuth();

  if (Object.keys(userLogInfo).length > 0) {
    return <RouteComponent />;
  }
  return <Navigate to="/" />;
};

BaseGuardRoutes.defaultProps = {
  path: ''
};
