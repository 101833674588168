import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreezUsersType, CreateUserResponse } from '../models/settings';
import {
  getBreezUsers,
  createBreezUserApi,
  updateBreezUserApi,
  changePasswordAPI,
  getUserProfileAPI,
  updateUserProfileAPI
} from '../apis/settings';
import {
  showErrorMessage,
  showSuccessMessage
} from 'src/shared/components/toaster/index';

interface TransactionState {
  breezUsers: BreezUsersType[];
  createUserResponse: CreateUserResponse;
  userData: any;
  loading: boolean;
  integrations: any[];
  integrationDetails: any[];
  integrationsPage: number;
  integrationsCount: number;
  loadIntegrationDetails: boolean;
  loadingChangePassword: boolean;
  changePasswordResponse: string;
  userProfile: any;
  loadingUserProfile: boolean;
  updateUserProfileResponse: string;
}
const initialState: TransactionState = {
  breezUsers: [] as BreezUsersType[],
  createUserResponse: {} as CreateUserResponse,
  userData: {} as any,
  loading: false,
  integrations: [] as any[],
  integrationDetails: [] as any[],
  integrationsPage: 0,
  integrationsCount: 10,
  loadIntegrationDetails: false,
  loadingChangePassword: false,
  changePasswordResponse: '',
  userProfile: {} as any,
  loadingUserProfile: false,
  updateUserProfileResponse: ''
};

export const getBreezUsersList = createAsyncThunk(
  'settings/getBreezUsersList',
  async () => {
    const response = await getBreezUsers();
    return response.data;
  }
);

export const createBreezUser = createAsyncThunk(
  'settings/createBreezUser',
  async (data: any) => {
    const response = await createBreezUserApi(data);
    return response.data;
  }
);

export const updateBreezUser = createAsyncThunk(
  'settings/updateBreezUser',
  async (data: any) => {
    const { email } = data;
    const response = await updateBreezUserApi(email, data);
    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  'settings/changePassword',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await changePasswordAPI(data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'settings/getUserProfile',
  async () => {
    const response = await getUserProfileAPI();
    return response.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  'settings/updateUserProfile',
  async (data: any) => {
    const response = await updateUserProfileAPI(data);
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setCreateUserResponse: (state, action) => {
      state.createUserResponse = action.payload;
    },
    setLoggedInUserData: (state, action) => {
      state.userData = action.payload;
    },
    setBreezUsersList: (state, action) => {
      state.breezUsers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBreezUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getBreezUsersList.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(getBreezUsersList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.createUserResponse = action.payload;
          }
        }
      )
      .addCase(createBreezUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateBreezUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateBreezUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.breezUsers = action.payload?.users;
          }
        }
      )
      .addCase(updateBreezUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.loadingChangePassword = true;
      })
      .addCase(
        changePassword.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingChangePassword = false;
          if (action.payload) {
            state.changePasswordResponse = action?.payload?.details;
            showSuccessMessage(action?.payload?.details);
          }
        }
      )
      .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
        state.loadingChangePassword = false;
        state.changePasswordResponse = '';
        showErrorMessage(action.payload?.details);
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loadingUserProfile = true;
      })
      .addCase(
        getUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserProfile = false;

          if (action.payload) {
            state.userProfile = action.payload;
          }
        }
      )
      .addCase(getUserProfile.rejected, (state) => {
        state.loadingUserProfile = false;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loadingUserProfile = true;
      })
      .addCase(
        updateUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingUserProfile = false;

          if (action.payload) {
            state.updateUserProfileResponse = action?.payload?.details;
            showSuccessMessage(action?.payload?.details);
          }
        }
      )
      .addCase(updateUserProfile.rejected, (state) => {
        state.loadingUserProfile = false;
        state.updateUserProfileResponse = '';
      });
  }
});

export const {
  setCreateUserResponse,
  setLoggedInUserData,
  setBreezUsersList,
  clearResults
} = settingsSlice.actions;
export default settingsSlice.reducer;
