import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getActivityTelemetryDataAPI,
  getAlertPriorityTelemetryDataAPI,
  getApiNameTelemetryDataAPI,
  getCloudAccountIDTelemetryDataAPI,
  getGeoLocationTelemetryDataAPI,
  getOperationTelemetryDataAPI,
  getAlertByTypeTelemetryDataAPI,
  getResourceTelemetryDataAPI,
  getServicesTelemetryDataAPI,
  getOperationCategoryTelemetryDataAPI,
  getWorkingHoursTelemetryDataAPI
} from '../apis/TelemetryApi';

interface TelemetryState {
  telemetryAPIPayload: {};
  alertByType: {};
  loadingAlertByType: boolean;
  alertPriority: {};
  loadingAlertPriorityData: boolean;
  activity: {};
  loadingActivity: boolean;
  operations: {};
  loadingOperations: boolean;
  resources: {};
  loadingResources: boolean;
  apiNames: {};
  loadingApiNames: boolean;
  geoLocations: {};
  loadingGeoLocations: boolean;
  services: {};
  loadingServices: boolean;
  cloudAccountID: {};
  loadingCloudAccountID: boolean;
  operationCategory: {};
  loadingOperationCategory: boolean;
  workingHours: {};
  loadingWorkingHours: boolean;
}
const initialState: TelemetryState = {
  telemetryAPIPayload: {},
  alertByType: {},
  loadingAlertByType: false,
  alertPriority: {},
  loadingAlertPriorityData: false,
  activity: {},
  loadingActivity: false,
  operations: {},
  loadingOperations: false,
  resources: {},
  loadingResources: false,
  apiNames: {},
  loadingApiNames: false,
  geoLocations: {},
  loadingGeoLocations: false,
  services: {},
  loadingServices: false,
  cloudAccountID: {},
  loadingCloudAccountID: false,
  operationCategory: {},
  loadingOperationCategory: false,
  workingHours: {},
  loadingWorkingHours: false
};

export const getAlertByTypeTelemetryData = createAsyncThunk(
  'telemetry/getAlertByTypeTelemetryData',
  async (data: any) => {
    const response = await getAlertByTypeTelemetryDataAPI(data);
    return response;
  }
);

export const getAlertPriorityTelemetryData = createAsyncThunk(
  'telemetry/getAlertPriorityTelemetryData',
  async (data: any) => {
    const response = await getAlertPriorityTelemetryDataAPI(data);
    return response;
  }
);

export const getActivityTelemetryData = createAsyncThunk(
  'telemetry/getActivityTelemetryData',
  async (data: any) => {
    const response = await getActivityTelemetryDataAPI(data);
    return response;
  }
);

export const getOperationTelemetryData = createAsyncThunk(
  'telemetry/getOperationTelemetryData',
  async (data: any) => {
    const response = await getOperationTelemetryDataAPI(data);
    return response;
  }
);

export const getResourceTelemetryData = createAsyncThunk(
  'telemetry/getResourceTelemetryData',
  async (data: any) => {
    const response = await getResourceTelemetryDataAPI(data);
    return response;
  }
);

export const getApiNameTelemetryData = createAsyncThunk(
  'telemetry/getApiNameTelemetryData',
  async (data: any) => {
    const response = await getApiNameTelemetryDataAPI(data);
    return response;
  }
);

export const getGeoLocationTelemetryData = createAsyncThunk(
  'telemetry/getGeoLocationTelemetryData',
  async (data: any) => {
    const response = await getGeoLocationTelemetryDataAPI(data);
    return response;
  }
);

export const getServicesTelemetryData = createAsyncThunk(
  'telemetry/getServicesTelemetryData',
  async (data: any) => {
    const response = await getServicesTelemetryDataAPI(data);
    return response;
  }
);

export const getCloudAccountIDTelemetryData = createAsyncThunk(
  'telemetry/getCloudAccountIDTelemetryData',
  async (data: any) => {
    const response = await getCloudAccountIDTelemetryDataAPI(data);
    return response;
  }
);

export const getOperationCategoryTelemetryData = createAsyncThunk(
  'telemetry/getOperationCategoryTelemetryData',
  async (data: any) => {
    const response = await getOperationCategoryTelemetryDataAPI(data);
    return response;
  }
);

export const getWorkingHoursTelemetryData = createAsyncThunk(
  'telemetry/getWorkingHoursTelemetryData',
  async (data: any) => {
    const response = await getWorkingHoursTelemetryDataAPI(data);
    return response;
  }
);

export const telemetrySlice = createSlice({
  name: 'telemetry',
  initialState,
  reducers: {
    setTelemetryAPIPayload: (state, action) => {
      state.telemetryAPIPayload = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      // getAlertByTypeTelemetryData
      .addCase(getAlertByTypeTelemetryData.pending, (state) => {
        state.loadingAlertByType = true;
      })
      .addCase(
        getAlertByTypeTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAlertByType = false;
          if (action.payload) {
            state.alertByType = action.payload;
          }
        }
      )
      .addCase(getAlertByTypeTelemetryData.rejected, (state) => {
        state.loadingAlertByType = false;
      })
      // getAlertPriorityTelemetryData
      .addCase(getAlertPriorityTelemetryData.pending, (state) => {
        state.loadingAlertPriorityData = true;
      })
      .addCase(
        getAlertPriorityTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingAlertPriorityData = false;
          if (action.payload) {
            state.alertPriority = action.payload;
          }
        }
      )
      .addCase(getAlertPriorityTelemetryData.rejected, (state) => {
        state.loadingAlertPriorityData = false;
      })

      //getActivityTelemetryData
      .addCase(getActivityTelemetryData.pending, (state) => {
        state.loadingActivity = true;
      })
      .addCase(
        getActivityTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingActivity = false;
          if (action.payload) {
            state.activity = action.payload;
          }
        }
      )
      .addCase(getActivityTelemetryData.rejected, (state) => {
        state.loadingActivity = false;
      })

      //getOperationTelemetryData
      .addCase(getOperationTelemetryData.pending, (state) => {
        state.loadingOperations = true;
      })
      .addCase(
        getOperationTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingOperations = false;
          if (action.payload) {
            state.operations = action.payload;
          }
        }
      )
      .addCase(getOperationTelemetryData.rejected, (state) => {
        state.loadingOperations = false;
      })

      //getResourceTelemetryData
      .addCase(getResourceTelemetryData.pending, (state) => {
        state.loadingResources = true;
      })
      .addCase(
        getResourceTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingResources = false;
          if (action.payload) {
            state.resources = action.payload;
          }
        }
      )
      .addCase(getResourceTelemetryData.rejected, (state) => {
        state.loadingResources = false;
      })

      //getApiNameTelemetryData
      .addCase(getApiNameTelemetryData.pending, (state) => {
        state.loadingApiNames = true;
      })
      .addCase(
        getApiNameTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingApiNames = false;
          if (action.payload) {
            state.apiNames = action.payload;
          }
        }
      )
      .addCase(getApiNameTelemetryData.rejected, (state) => {
        state.loadingApiNames = false;
      })

      //getGeoLocationTelemetryData
      .addCase(getGeoLocationTelemetryData.pending, (state) => {
        state.loadingGeoLocations = true;
      })
      .addCase(
        getGeoLocationTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingGeoLocations = false;
          if (action.payload) {
            state.geoLocations = action.payload;
          }
        }
      )
      .addCase(getGeoLocationTelemetryData.rejected, (state) => {
        state.loadingGeoLocations = false;
      })

      //getServicesTelemetryData
      .addCase(getServicesTelemetryData.pending, (state) => {
        state.loadingServices = true;
      })
      .addCase(
        getServicesTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingServices = false;
          if (action.payload) {
            state.services = action.payload;
          }
        }
      )
      .addCase(getServicesTelemetryData.rejected, (state) => {
        state.loadingServices = false;
      })

      //getCloudAccountIDTelemetryData
      .addCase(getCloudAccountIDTelemetryData.pending, (state) => {
        state.loadingCloudAccountID = true;
      })
      .addCase(
        getCloudAccountIDTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingCloudAccountID = false;
          if (action.payload) {
            state.cloudAccountID = action.payload;
          }
        }
      )
      .addCase(getCloudAccountIDTelemetryData.rejected, (state) => {
        state.loadingCloudAccountID = false;
      })

      //getOperationCategoryTelemetryData
      .addCase(getOperationCategoryTelemetryData.pending, (state) => {
        state.loadingOperationCategory = true;
      })
      .addCase(
        getOperationCategoryTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingOperationCategory = false;
          if (action.payload) {
            state.operationCategory = action.payload;
          }
        }
      )
      .addCase(getOperationCategoryTelemetryData.rejected, (state) => {
        state.loadingOperationCategory = false;
      })

      //getWorkingHoursTelemetryData
      .addCase(getWorkingHoursTelemetryData.pending, (state) => {
        state.loadingWorkingHours = true;
      })
      .addCase(
        getWorkingHoursTelemetryData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadingWorkingHours = false;
          if (action.payload) {
            state.workingHours = action.payload;
          }
        }
      )
      .addCase(getWorkingHoursTelemetryData.rejected, (state) => {
        state.loadingWorkingHours = false;
      });
  }
});
export const { setTelemetryAPIPayload } = telemetrySlice.actions;
export default telemetrySlice.reducer;
