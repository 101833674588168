import { DB_TYPE } from 'src/shared/constants/constants';
import { apiPost } from 'src/shared/utils/api-request';

export const getAwsAlertsAPI = async (data: any) => {
  const res = await apiPost('alerts', data);
  return res;
};

export const alertStatusChangeAPI = async (
  alert_id: string,
  status: string,
  payload: any
) => {
  const res = await apiPost(`alerts/${alert_id}/${status}`, payload);
  return res;
};

export const RemediateAlertsAPI = async (alert_id: string) => {
  const res = await apiPost(`alerts/${alert_id}/remediate`);
  return res;
};

export const CloseAlertsAPI = async (alert_id: string) => {
  const res = await apiPost(`alerts/${alert_id}/close`);
  return res;
};

export const investigateAlertDetailsAPI = async (data: any) => {
  const res = await apiPost(`runtime/all-data-source${DB_TYPE}`, data);
  return res;
};

export const getAlertDetailsAPI = async (data: any) => {
  const alertId = data?.alertId;
  const res = await apiPost(`alerts/${alertId}/details`);
  return res;
};

export const createJiraTicketApi = async (data: any) => {
  const res = await apiPost('jira/create', data);
  return res;
};

export const getTimelineDataAPI = async (data: any) => {
  const res = await apiPost('runtime/all-data-source?db_type=athena_v2', data);
  return res;
};

export const markFalsePositiveAPI = async (alert_id: string, data: any) => {
  const res = await apiPost(`alerts/${alert_id}/false-positive`, data);
  return res;
};

export const getSlackUsersAPI = async () => {
  const res = await apiPost(`slack/get-users`);
  return res;
};

export const sendSlackDmAPI = async (data: any) => {
  const res = await apiPost(`slack/send-dm`, data);
  return res.data;
};

export const generateAIReportAPI = async (data: any) => {
  const res = await apiPost('gen-ai-report/generate', data);
  return res;
};
