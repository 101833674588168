import { showErrorMessage } from 'src/shared/components/toaster';
import { apiGet, apiPost } from 'src/shared/utils/api-request';

export const getUsersIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/users', data);
  return res;
};

export const getGroupsIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/groups', data);
  return res;
};

export const getRolesIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/roles', data);
  return res;
};

export const getNonHumanIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/access-keys', data);
  return res;
};

export const getAppsIdentityAPI = async (data: any) => {
  const res = await apiPost('identity/applications', data);
  return res;
};

export const getGraphDataAPI = async (payload: any) => {
  try {
    const res = await apiPost(`graph`, { graph: 'access', ...payload });
    return res?.data;
  } catch (error) {
    showErrorMessage(error?.message);
  }
};
