import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getIntegrationsApi,
  integrationsDetailApi,
  updateIntegrationsApi,
  updateWebhookDetailApi,
  webhookDetailApi
} from '../apis/integration';
import {
  showErrorMessage,
  showSuccessMessage
} from 'src/shared/components/toaster';

interface IntegrationState {
  loading: boolean;
  integrations: any[];
  integrationDetails: any[];
  integrationsPage: number;
  integrationsCount: number;
  loadIntegrationDetails: boolean;
  loadWebhook: boolean;
  webhooks: any[];
  webhooksPage: number;
  webhooksCount: number;
}

const initialState: IntegrationState = {
  loading: false,
  integrations: [] as any[],
  integrationDetails: [] as any[],
  integrationsPage: 0,
  integrationsCount: 10,
  loadIntegrationDetails: false,
  loadWebhook: false,
  webhooks: [] as any[],
  webhooksPage: 0,
  webhooksCount: 10
};

export const getIntegrations = createAsyncThunk(
  'integration/getIntegrations',
  async () => {
    const response = await getIntegrationsApi();
    return response.data;
  }
);

export const updateIntegrations = createAsyncThunk(
  'integration/updateIntegrations',
  async (data: any) => {
    const response = await updateIntegrationsApi(data);
    return response.data;
  }
);

export const getIntegrationsDetails = createAsyncThunk(
  'integration/getIntegrationsDetails',
  async (data: any) => {
    const response = await integrationsDetailApi(data);
    return response.data;
  }
);

export const getWebhookDetails = createAsyncThunk(
  'integration/getWebhookDetails',
  async (data: any) => {
    const response = await webhookDetailApi(data);
    return response.data;
  }
);

export const addWebhookDetails = createAsyncThunk(
  'integration/addWebhookDetails',
  async (data: any) => {
    const response = await updateWebhookDetailApi(data);
    return response.data;
  }
);

export const updateWebhookDetails = createAsyncThunk(
  'integration/updateWebhookDetails',
  async (data: any) => {
    const response = await updateWebhookDetailApi(data);
    return response.data;
  }
);

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    setIntegrationsPage: (state, action) => {
      state.integrationsPage = action.payload;
    },
    setWebhooksPage: (state, action) => {
      state.webhooksPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getIntegrations.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            state.integrations = action.payload;
          }
        }
      )
      .addCase(getIntegrations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateIntegrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateIntegrations.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            showSuccessMessage('Successfully updated Integration details');
          }
        }
      )
      .addCase(updateIntegrations.rejected, (state) => {
        state.loading = false;
        showErrorMessage('Error while updating integration');
      })
      .addCase(getIntegrationsDetails.pending, (state) => {
        state.loadIntegrationDetails = true;
      })
      .addCase(
        getIntegrationsDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadIntegrationDetails = false;
          if (action.payload) {
            state.integrationDetails = action.payload?.items;
            state.integrationsCount = action.payload?.total_count;
          }
        }
      )
      .addCase(getIntegrationsDetails.rejected, (state) => {
        state.loadIntegrationDetails = false;
      })
      .addCase(getWebhookDetails.pending, (state) => {
        state.loadWebhook = true;
      })
      .addCase(
        getWebhookDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadWebhook = false;
          if (action.payload) {
            state.webhooks = action.payload?.items?.map((item) => {
              return { id: item?.webhook_id, ...item };
            });
            state.webhooksCount = action.payload?.total_count;
          }
        }
      )
      .addCase(getWebhookDetails.rejected, (state) => {
        state.loadWebhook = false;
      })
      .addCase(addWebhookDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addWebhookDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            showSuccessMessage(
              'Successfully added Webhook Integration details'
            );
          }
        }
      )
      .addCase(addWebhookDetails.rejected, (state) => {
        state.loading = false;
        showErrorMessage('Error while adding webhook integration');
      })
      .addCase(updateWebhookDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateWebhookDetails.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload) {
            showSuccessMessage(
              'Successfully updated Webhook Integration details'
            );
          }
        }
      )
      .addCase(updateWebhookDetails.rejected, (state) => {
        state.loading = false;
        showErrorMessage('Error while updating webhook integration');
      });
  }
});

export const { setIntegrationsPage, setWebhooksPage } =
  integrationSlice.actions;

export default integrationSlice.reducer;
