import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSlackUsersAPI } from '../apis/AwsAlertsApi';

interface TransactionState {
  slackUsers: any[];
  loadSlackUsers: boolean;
}
const initialState: TransactionState = {
  slackUsers: [] as any[],
  loadSlackUsers: false
};

export const getSlackUsers = createAsyncThunk(
  'alertAction/getSlackUsers',
  async () => {
    const response = await getSlackUsersAPI();
    return response.data;
  }
);

export const alertActionSlice = createSlice({
  name: 'alertAction',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSlackUsers.pending, (state) => {
        state.loadSlackUsers = true;
      })
      .addCase(
        getSlackUsers.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.loadSlackUsers = false;
          if (action.payload) {
            state.slackUsers = action.payload;
          }
        }
      )
      .addCase(getSlackUsers.rejected, (state) => {
        state.loadSlackUsers = false;
      });
  }
});

export default alertActionSlice.reducer;
