import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/providers/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
        <ToastContainer hideProgressBar autoClose={3000} />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
