import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { DISTRIBUTION_KEY_MAPPING } from 'src/shared/constants/constants';

export const DistributionChart = ({
  chartLabels,
  handleDataPointSelection,
  handleDistributionChartCenterClick
}) => {
  const theme = useTheme();

  const getLabelFromMapping = (value: string) => {
    const mapping = DISTRIBUTION_KEY_MAPPING.find(
      (item) => item?.key === value
    );
    return mapping?.value;
  };

  const barChartptions: ApexOptions = {
    chart: {
      type: 'donut',
      // offsetY: 20,
      height: 250,
      events: {
        click(e, _chart, _options) {
          // Perform action when the total value is clicked
          if (
            e.srcElement instanceof SVGElement &&
            e.srcElement.tagName.toLowerCase() === 'circle'
          ) {
            handleDistributionChartCenterClick();
          }
        },
        dataPointSelection: (e, chart, options) => {
          handleDataPointSelection(e, chart, options);
        },
        animationEnd: function (ctx) {
          ctx.toggleDataPointSelection(1);
        }
      }
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 150
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    stroke: {
      width: 0
    },
    states: {
      active: {
        filter: {
          value: 1,
          type: 'asa'
        }
      }
    },
    colors: [
      '#1F6CAA',
      '#009EF3',
      '#2A7FB8',
      '#2C9EDB',
      '#4A6FA0',
      '#3F85C1',
      '#2E6892',
      '#3694C6',
      '#487DAA',
      '#5C9FDC'
    ],
    title: {
      text: ''
    },
    series: Array(chartLabels?.length).fill(40),
    labels: chartLabels?.map((label) => getLabelFromMapping(label)),
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        fontWeight: 500,
        colors: ['#ffffff']
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.seriesIndex].split(' ');
      },
      dropShadow: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '40%',
          background: '#1e1f24',

          labels: {
            show: true,
            total: {
              fontSize: '12',
              show: true,
              color: '#fff',
              // label: 'All Identity Fingerprint',
              // label: 'All Activity',
              label: '',

              showAlways: true,
              formatter: function (w) {
                return 'All Activity';
              }
            },
            value: {
              offsetY: -15,
              show: true,
              fontSize: '12',
              color: '#fff'
            }
          }
        }
      }
    },
    legend: {
      show: false
    }
  };
  const [chartOptions, setChartOptions] = useState(barChartptions);

  return (
    <Chart
      height={250}
      options={chartOptions}
      series={chartOptions.series}
      type="donut"
    />
  );
};
