import { apiPost } from 'src/shared/utils/api-request';

export const getAlertByTypeTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/alerts-type', payload);
  return res.data;
};

export const getAlertPriorityTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/alert-priority', payload);
  return res.data;
};

export const getActivityTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/activity', payload);
  return res.data;
};

export const getOperationTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/operations', payload);
  return res.data;
};

export const getResourceTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/resources', payload);
  return res.data;
};

export const getApiNameTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/api-names', payload);
  return res.data;
};

export const getGeoLocationTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/geo-locations', payload);
  return res.data;
};

export const getServicesTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/services', payload);
  return res.data;
};

export const getCloudAccountIDTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/cloud-account-id', payload);
  return res.data;
};

export const getOperationCategoryTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/operation-category', payload);
  return res.data;
};

export const getWorkingHoursTelemetryDataAPI = async (payload: any) => {
  const res = await apiPost('identity/telemetry/working-hours', payload);
  return res.data;
};
